import React from 'react';
import { View, useSx } from 'dripsy';


export const Footer = ({ clientConfig }:{clientConfig:any}) => {
  const sx = useSx();

  return (
    <View
      style={sx({
        backgroundColor: '#F3F1FA',
        marginX: [3, null, null, null, 4],
        mb: 30,
        borderRadius: [14, 16],
        overflow: 'hidden',
      })}
    >
      
       
    </View>
  );
};
