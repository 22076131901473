import React from 'react';
import { View, useSx } from 'dripsy';
import { ViewStyle } from 'react-native';

export function Container(props: { children: React.ReactNode;style?:ViewStyle  }) {
  const sx = useSx()
  return (
    <View
    style={[
      sx({
        flex: 1,
        height: '100%',
        width: '100%',
        backgroundColor: '$white',
        maxWidth:768,
      }),
      props.style
    ]}
    >
      {props.children}
    </View>
  );
}
