import { NavBar } from 'app/features/layout/navbar'
import { SxProp, useSx, View } from 'dripsy'
import React, { ReactNode, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import * as globalAction from 'app/redux/actions/global.action'
import {
  Platform,
  SafeAreaView,
  ScrollViewProps,
  ViewStyle,
} from 'react-native'
import { Footer } from 'app/features/layout/footer'
import { useRouter } from 'solito/router'
import { useCurrentRoute } from 'app/redux/hooks/use-current-route'
import { useDim } from 'app/redux/hooks/useDim'
import { Container } from 'app/components/container'
import { KeyboardAwareScrollView } from 'app/components/keyboard-aware-scroll-view'

const ComponentLayout = ({
  user,
  children,
  logout,
  isNavBar = true,
  isPrivatePage = false,
  showFullFooter = false,
  loaded,
  containerStyle,
  clientConfig,
  isProfileIcon = true,
  isBackIcon,
  onBackClick,
  isShowFriendIcon,
  scrollEnabled = true,
  scrollViewProps = {},
  navBarComponent,
  mainContainerStyle,
  subContainerStyle={},
  loginSignUpBtn,
  logoPositionCenter,
  goToDashBoardButton
}: {
  user?: any
  children?: any
  logout?: (navigate: any) => void
  isNavBar?: boolean
  isPrivatePage?: boolean
  loaded?: boolean
  containerStyle?: ViewStyle
  mainContainerStyle?: ViewStyle
  clientConfig?: any
  leftMenu?: boolean
  showFullFooter?: boolean
  isProfileIcon?: boolean
  isBackIcon?: boolean
  onBackClick?: () => void
  isShowFriendIcon?: boolean
  scrollEnabled?: boolean
  scrollViewProps?: ScrollViewProps
  navBarComponent?: ReactNode,
  subContainerStyle?:SxProp
  loginSignUpBtn?:boolean
  logoPositionCenter?:boolean,
  goToDashBoardButton?:boolean
}) => {
  const sx = useSx()
  const { height } = useDim()
  const { replace } = useRouter()
  const currentRoute = useCurrentRoute()

  useEffect(() => {
    if (!loaded) return

    if (isPrivatePage && !user?._id && currentRoute) {
      let _currentRoute = currentRoute
      if (Platform.OS === 'web')
        replace(`/account/signin?continue=${_currentRoute}`)
      else replace('/account/signin')
    }
  }, [user, currentRoute, isPrivatePage, loaded])


  return (
    <SafeAreaView
      style={sx({
        flex: 1,
        // height: '100%',
      })}
    >
      <View
        style={sx({
          flex: 1,
          display: 'flex',
          overflow: 'hidden',
          minHeight: [height + 10, height],
          backgroundColor: '$white',
        })}
      >
        {isNavBar ? (
          navBarComponent ? (
            navBarComponent
          ) : (
            <NavBar
              user={user}
              logout={logout}
              clientConfig={clientConfig}
              isProfileIcon={isProfileIcon}
              isBackIcon={isBackIcon}
              onBackClick={onBackClick}
              isShowFriendIcon={isShowFriendIcon}
              loginSignUpBtn={loginSignUpBtn}
              logoPositionCenter={logoPositionCenter}
              goToDashBoardButton={goToDashBoardButton}
            />
          )
        ) : (
          <></>
        )}
        <View
          style={[sx({
            flex: 1,
            display: 'flex',
            flexDirection: 'row-reverse',
            marginTop: isNavBar  ? 60 : 0,
            justifyContent: 'center',
            alignItems: 'center',
          }),mainContainerStyle]}
        >
          {scrollEnabled ? (
            <KeyboardAwareScrollView
              contentContainerStyle={{ flexGrow: 1 }}
              nestedScrollEnabled={scrollEnabled}
              scrollEnabled={scrollEnabled}
              style={[
                sx({
                  flex: 1,
                  height: '100%',
                  width: '100%',
                  backgroundColor: '$white',
                  maxWidth: 768,
                  ...subContainerStyle
                }),
              ]}
              {...scrollViewProps}
            >
              <View
                style={[
                  sx({
                    minHeight: height - 100,
                    flex: 1,
                    paddingX: [8, 16],
                    paddingY: [2, 4],
                  }),
                  containerStyle,
                ]}
              >
                {children}
              </View>
              {showFullFooter && <Footer clientConfig={clientConfig} />}
            </KeyboardAwareScrollView>
          ) : (
            <Container
              style={sx({
                flex: 1,
                height: '100%',
                width: '100%',
                backgroundColor: '$white',
                maxWidth: 768,
                ...subContainerStyle
              })}
            >
              <View
                style={[
                  sx({
                    minHeight: height - 100,
                    flex: 1,
                    paddingX: [8, 16],
                    paddingY: [2, 4],
                  }),
                  containerStyle,
                ]}
              >
                {children}
              </View>
              {showFullFooter && <Footer clientConfig={clientConfig} />}
            </Container>
          )}
        </View>
      </View>
    </SafeAreaView>
  )
}

const mapStateToProps = ({ user, loaded, clientConfig }: any) => ({
  user,
  loaded,
  clientConfig,
})

const Layout = connect(mapStateToProps, {
  ...globalAction,
})(ComponentLayout)

export default Layout
