import React, { useEffect, useState } from 'react';
import { ActivityIndicator, ImageProps, StyleSheet, ViewStyle } from 'react-native'; 
import {Image as ImageReactNative,} from 'dripsy'
 interface ImageWithLoaderProps extends ImageProps {
  loaderSize?: 'small' | 'large';
  loaderColor?: string;
  containerStyle?:ViewStyle
}

export const Image: React.FC<ImageWithLoaderProps> = ({
  loaderSize = 'small',
  loaderColor = '#0000ff',
  containerStyle,
  defaultSource,
  source,
  style,
  ...props
}:any) => {
  const [imageSource, setImageSource] = useState(source);
  const [loading, setLoading] = useState(true);
  const [imageError, setImageError] = useState(false);
  const handleImageLoad:any = (error) => {
    setLoading(false);
  };

  useEffect(()=>{
    setImageSource(source)
  },[source])

  return (
    <>
      <ImageReactNative
        source={imageError?defaultSource:imageSource}
        onLoadEnd={handleImageLoad}
        onError={()=>{
          setLoading(false);
          setImageError(true)
        }}
        style={[{
          borderColor:'rgb(93 93 93 / 10%)',
          borderWidth:1,
        },style]}
        {...props}
      />
      {loading && (
        <ActivityIndicator
          size={loaderSize}
          style={styles.loader}
        />
      )}
    </>
  );
};

const styles = StyleSheet.create({
  loader: {
    alignSelf:'center',
    position:'absolute'
  }
});

